.projectsContainerStyles {
  display: flex;
  max-width: 1728px;
  width: 100%;
  margin: auto;
  padding: 144px 48px 96px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  background: #fff;
}
.projectsCardsOuterContainerStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 96px;
}
.projectPageHeadingTextStyles {
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  width: 100%;
  max-width: 784px;
}
.tabNamesContainerStyles {
  border-top: 1px solid;
  padding-top: 24px;
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 24px;
  overflow: scroll;
}
.activeTabNameTextStyles,
.tabNameTextStyles {
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  padding-bottom: 8px;
  border-bottom: 2px solid #050505;
  cursor: pointer;
}
.tabNameTextStyles {
  color: rgba(5, 5, 5, 0.5);
  border-bottom: none;
}
.individualProjectsContainerStyles,
.individualProjectsContainerStyles2,
.individualProjectsContainerStyles3 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.individualProjectsContainerStyles {
  justify-content: flex-end;
}
.individualProjectsContainerStyles2 {
  justify-content: center;
  gap: 24px;
}
.individualProjectsContainerStyles3 {
  justify-content: space-between;
}
.projectSmallCardStyles,
.projectSmallCardStyles1 {
  width: 384px;
}
.projectBigCardStyles {
  width: 784px;
}
.projectBigCardImageStyles {
  width: 100%;
  height: 468px;
}
.projectsBottomSectionStyles {
  padding-bottom: 96px;
  width: 100%;
  max-width: 1728px;
  margin: auto;
}
.projectContainerStyles {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  transition: all 0.2s ease-in-out;
  border-top: 1px solid #cdcdcd;
  max-width: 1728px;
  overflow: hidden;
}
.projectContainerStyles:last-child {
  border-bottom: 1px solid #cdcdcd;
}
.projectNameContainerStyles {
  display: flex;
  flex-direction: row;
  width: fit-content;
  color: var(--dark);
  transition: all 0.3s ease-in-out;
  padding-left: 48px;
}
.projectNameTextStyles {
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  white-space: nowrap;
  line-height: 56px;
}
.rightUpArrowStyles {
  width: 56px;
  height: 56px;
  opacity: 0;
  display: none;
}
.rightUpArrowPathStyles {
  stroke: var(--white);
}
.projectTypeTextStyles {
  white-space: nowrap;
  color: var(--white);
  width: fit-content;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.projectContainerStyles:hover {
  background-color: var(--dark);
  gap: 8px !important;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.projectContainerStyles:hover .rightUpArrowStyles {
  display: block;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.projectContainerStyles:hover .projectNameContainerStyles {
  color: var(--white);
  transition: all 0.3s ease-in-out;
}
.projectContainerStyles:hover .projectTypeTextStyles {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

/********MEDIA QUERIES ********/

@media screen and (min-width: 1728px) {
  .projectNameContainerStyles {
    padding-left: 0px;
  }
  .projectsContainerStyles {
    padding: 144px 64px 96px 64px;
  }
  .projectPageHeadingTextStyles {
    font-size: 72px;
    line-height: 80px;
  }
  .projectBigCardStyles {
    width: 880px;
  }
  .projectBigCardImageStyles {
    height: 520px;
  }
  .projectSmallCardStyles,
  .projectSmallCardStyles1 {
    width: 424px;
  }
  .projectsBottomSectionStyles {
    padding-inline: 64px;
  }
  .projectSmallCardImageStyles1,
  .projectSmallCardImageStyles {
    height: 424px;
  }
  .projectNameTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .projectTypeTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
  .rightUpArrowStyles {
    width: 64px;
    height: 64px;
  }
}
@media screen and (min-width: 1513px) and (max-width: 1728px) {
  .projectsContainerStyles {
    padding: 144px 64px 96px 64px;
  }
  .projectPageHeadingTextStyles {
    font-size: 72px;
    line-height: 80px;
  }
  .projectBigCardStyles {
    width: 880px;
  }
  .projectBigCardImageStyles {
    height: 520px;
  }
  .projectSmallCardStyles,
  .projectSmallCardStyles1 {
    width: 424px;
  }
  .projectSmallCardImageStyles1,
  .projectSmallCardImageStyles {
    height: 424px;
  }
  .projectNameTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .projectTypeTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
  .rightUpArrowStyles {
    width: 64px;
    height: 64px;
  }
}
@media screen and (max-width: 1366px) {
  .projectsContainerStyles {
    padding: 120px 48px 96px 48px;
  }
  /* .projectPageHeadingTextStyles {
    font-size: 56px;
    line-height: 64px;
  } */
  .projectBigCardImageStyles {
    height: 448px;
  }
  .projectNameTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .rightUpArrowStyles {
    width: 48px;
    height: 48px;
  }
}
@media screen and (max-width: 1148px) {
}
@media screen and (max-width: 1079px) {
  .projectPageHeadingTextStyles {
    font-size: 48px;
    line-height: 56px;
  }
  .projectsContainerStyles {
    padding: 96px 32px;
  }
  .projectNameContainerStyles {
    padding-left: 32px;
  }
  .projectsCardsOuterContainerStyles {
    gap: 72px;
  }
  .projectSmallCardStyles1,
  .projectSmallCardStyles {
    width: 320px;
  }
}
@media screen and (max-width: 895px) {
  .projectPageHeadingTextStyles {
    font-size: 48px;
    line-height: 56px;
  }
  .projectsContainerStyles {
    padding: 96px 24px 80px 24px;
    gap: 40px;
  }
  .projectNameContainerStyles {
    padding-left: 24px;
  }
  .projectBigCardStyles {
    width: 384px;
  }
  .projectBigCardImageStyles {
    height: 384px;
  }
  .individualProjectsContainerStyles3 {
    flex-direction: column;
    gap: 48px;
  }
  .individualProjectsContainerStyles3 .projectSmallCardStyles:first-child {
    margin-left: auto;
    width: 384px;
  }
  .individualProjectsContainerStyles3 .projectSmallCardImageStyles {
    height: 384px;
    width: 100%;
  }
  .projectSmallCardStyles,
  .projectSmallCardStyles1 {
    width: 286px;
  }
  .projectsCardsOuterContainerStyles {
    gap: 48px;
  }
  .individualProjectsContainerStyles2 {
    justify-content: flex-start;
  }
  .projectNameTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .rightUpArrowStyles {
    width: 36px;
    height: 36px;
  }
}
@media screen and (max-width: 567px) {
  .projectPageHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .projectsContainerStyles {
    padding: 96px 20px 72px 20px;
    gap: 32px;
  }
  .projectNameContainerStyles {
    padding-left: 20px;
  }
  .projectSmallCardStyles,
  .projectBigCardStyles,
  .projectBigCardStyles1,
  .projectSmallCardStyles1 {
    width: 100%;
  }
  .projectBigCardImageStyles,
  .projectSmallCardImageStyles {
    height: 374px;
  }
  .projectsCardsOuterContainerStyles,
  .individualProjectsContainerStyles3 {
    gap: 40px;
  }
  .individualProjectsContainerStyles3 .projectSmallCardStyles:first-child {
    margin-left: auto;
    width: 100%;
  }
  .individualProjectsContainerStyles3 .projectSmallCardImageStyles {
    height: 374px;
    width: 100%;
  }
}
@media screen and (max-width: 360px) {
  .projectsContainerStyles {
    padding: 96px 20px 64px 20px;
  }
  .projectPageHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .projectBigCardImageStyles,
  .projectSmallCardImageStyles {
    height: 280px;
  }
  .projectsCardsOuterContainerStyles,
  .individualProjectsContainerStyles3 {
    gap: 40px;
  }
  .individualProjectsContainerStyles3 .projectSmallCardStyles:first-child {
    margin-left: auto;
    width: 100%;
  }
  .individualProjectsContainerStyles3 .projectSmallCardImageStyles {
    height: 280px;
    width: 100%;
  }
  .rightUpArrowStyles,
  .projectTypeTextStyles {
    display: none;
  }
  .projectContainerStyles {
    justify-content: flex-start;
  }
  .projectContainerStyles:hover .rightUpArrowStyles {
    display: none;
  }
}

@media (hover: none) {
  .projectContainerStyles:hover {
    background-color: var(--white);
    gap: 8px !important;
    justify-content: initial;
    transition: all 0.3s ease-in-out;
  }
  .projectContainerStyles:hover .rightUpArrowStyles {
    display: none;
    opacity: 0;
  }
  .projectContainerStyles:hover .projectNameContainerStyles {
    color: var(--dark);
  }
  .projectContainerStyles:hover .projectTypeTextStyles {
    opacity: 0;
  }
}
