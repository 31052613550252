.serviceCardContainerStyles {
  color: var(--dark);
  display: flex;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 384px;
  height: fit-content;
  flex: 1 0 0;
  border-radius: 12px;
  border: 1px solid rgba(5, 5, 5, 0.2);
  background: #fff;
  transition: all 0.2s ease-in-out;
}
.serviceCardContainerStyles:hover {
  background-color: #dadee3;
  border: 1px solid #dadee3;
  transition: all 0.2s ease-in-out;
}
.serviceCardContainerStyles:hover .rightUpArrowPathStyles {
  stroke: var(--blue);
  transition: all 0.2s ease-in-out;
}
.serviceContentContainerStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
}
.serviceNameTextStyles {
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.imageWidthStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.serviceIonStyles {
  width: 48px;
  height: 48px;
}
.serviceDescTextStyles {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px; /* 150% */
}
.rightUpArrowStyles {
  width: 48px;
  height: 48px;
}
.rightUpArrowPathStyles {
  stroke: var(--dark);
  transition: all 0.2s ease-in-out;
}

@media screen and (min-width: 1729px) {
  .serviceCardContainerStyles {
    width: 432px;
  }
  .serviceNameTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .serviceIonStyles,
  .rightUpArrowStyles {
    width: 64px;
    height: 64px;
  }
  .serviceDescTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (min-width: 1513px) and (max-width: 1729px) {
  .serviceCardContainerStyles {
    width: 432px;
  }
  .serviceNameTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .serviceIonStyles,
  .rightUpArrowStyles {
    width: 64px;
    height: 64px;
  }
  .serviceDescTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (max-width: 1366px) {
  .serviceNameTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
}
@media screen and (max-width: 895px) {
  .serviceCardContainerStyles {
    width: 360px;
  }
  .serviceContentContainerStyles {
    gap: 40px;
  }
  .serviceIonStyles {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 567px) {
  .serviceCardContainerStyles {
    width: 320px;
    padding: 16px;
  }
  .serviceContentContainerStyles {
    gap: 32px;
  }
  .serviceIonStyles,
  .rightUpArrowStyles {
    width: 32px;
    height: 32px;
  }
}
@media screen and (max-width: 360px) {
  .serviceCardContainerStyles {
    width: 256px;
    gap: 20px;
  }
  .serviceContentContainerStyles {
    gap: 24px;
  }
  .serviceNameTextStyles {
    font-size: 20px;
    line-height: 28px;
  }
}

@media (hover: none) {
  .serviceCardContainerStyles:hover {
    background-color: var(--white);
    border: 1px solid #dadee3;
  }
  .serviceCardContainerStyles:hover .rightUpArrowPathStyles {
    stroke: var(--dark);
  }
  .serviceCardContainerStyles:active {
    background-color: #dadee3;
    border: 1px solid #dadee3;
  }
  .serviceCardContainerStyles:active .rightUpArrowPathStyles {
    stroke: var(--blue);
  }
}
