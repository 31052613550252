.imageWidthStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contactMainContainerStyles {
  background: #1a1a1a;
  color: #ffffff;
}
.navbarStyles {
  background-color: #1a1a1a;
  color: #ffffff;
}
.contactContainerStyles {
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 1728px;
  padding: 144px 48px 96px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 96px;
}
.contactHeadingTextStyles {
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  width: 100%;
  max-width: 784px;
  margin-right: auto;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 88px;
}
.idNumberTextStyles,
.designationTextStyles {
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.profilesContainerStyles,
.contactFormContainerStyles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.profilesRightContainerStyles {
  display: flex;
  width: 100%;
  max-width: 784px;
  align-items: flex-start;
  gap: 24px;
}
.profileCardStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
}
.profileImageStyles {
  width: 380px;
  height: 380px;
  /* border-radius: 6px;
  overflow: hidden; */
}
.profileNameTextStyles {
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
.designationTextStyles {
  color: rgba(255, 255, 255, 0.5);
}
.mailTextStyles,
.phoneNumberTextStyles {
  cursor: pointer;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  width: fit-content;
  /* text-decoration-line: underline; */
  border-bottom: 1px solid var(--white);
  /* padding-bottom: 2px; */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.5); */
}
.contactFormStyles {
  width: 100%;
  max-width: 784px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.contactFormInputsContainerStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.contactFormBottomStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
.inputTextViewStyle,
.inputTextAreaViewStyle {
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  padding: 16px 24px;
  width: 100%;
  /* outline: none; */
  background-color: #1a1a1a;
  caret-color: rgba(255, 255, 255, 0.5);
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.inputTextViewStyle:active {
  border: 1px solid var(--white);
}
.inputTextAreaViewStyle {
  height: 176px;
  resize: none;
}
.inputCheckboxViewStyle {
  margin-right: 12px;
}
.privacyPolicyTextStyles {
  display: flex;
  flex-direction: row;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  white-space: nowrap;
  flex-wrap: wrap;
}
.privacyPolicyTextStyles span {
  color: #fff;
  cursor: pointer;
  width: fit-content;
  text-decoration: underline;
  /* border-bottom: 1px solid var(--white); */
  margin-left: 4px;
}
.sendMessageTextStyles {
  cursor: pointer;
  background: none;
  border: none;
  color: #fff;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  width: fit-content;
  /* text-decoration-line: underline; */
  border-bottom: 1px solid var(--white);
}
.customDividerStyles {
  background-color: rgba(255, 255, 255, 0.2);
}
.footerMainContainerStyles {
  padding: 48px;
  height: fit-content;
}
.footerStyles {
  border-top: none;
  padding-top: 0px;
}
/******** popup styles *************/

.popUpContainerStyles {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: rgba(25, 26, 25, 0.5);
  left: 0;
  top: 0;
  z-index: 12;
}
.popUpContentStyles {
  background: #00ff9b;
  color: var(--dark);
  position: fixed;
  height: fit-content;
  bottom: 0;
  transform: scaleY(100%);
  width: 100%;
  transition: all 3s ease-in-out;
}
/* .popUpContentHideStyles {
  background: #00ff9b;
  color: var(--dark);
  position: fixed;
  height: fit-content;
  bottom: 0;
  transform: scaleY(0%);
  width: 100%;
  transition: all 3s ease-in-out;
} */
.popUpContentInnerStyles {
  width: 100%;
  max-width: 1728px;
  margin: auto;
  display: flex;
  padding: 8px 48px;
  align-items: center;
  gap: 32px;
  justify-content: space-between;
  align-self: stretch;
}
.closeTextStyles,
.popUpContentTextStyles {
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeTextStyles {
  cursor: pointer;
  gap: 4px;
  display: none;
}
.crossMarkIconStyles {
  height: 16px;
  width: 16px;
}
.crossMarkIconPathStyles {
  stroke: var(--dark);
}
.errMsgTextStyle {
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: var(--error);
}
/***********MEDIA QUERIES ***********/
@media screen and (min-width: 1729px) {
  .contactContainerStyles {
    padding: 144px 64px 96px 64px;
  }
  .profilesRightContainerStyles,
  .contactFormStyles {
    max-width: 880px;
  }
  .profileNameTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .designationTextStyles,
  .idNumberTextStyles,
  .inputTextViewStyle,
  .privacyPolicyTextStyles,
  .privacyPolicyTextStyles span,
  .inputTextAreaViewStyle {
    font-size: 18px;
    line-height: 26px;
  }
  .phoneNumberTextStyles,
  .mailTextStyles,
  .sendMessageTextStyles {
    font-size: 20px;
    line-height: 28px;
  }
  .profileImageStyles {
    width: 428px;
    height: 428px;
    /* border-radius: 6px;
    overflow: hidden; */
  }
  .popUpContentTextStyles,
  .closeTextStyles {
    font-size: 20px;
    line-height: 28px;
  }
}
@media screen and (min-width: 1513px) and (max-width: 1728px) {
  .contactContainerStyles {
    padding: 144px 64px 96px 64px;
  }
  .profilesRightContainerStyles,
  .contactFormStyles {
    max-width: 880px;
  }
  .profileNameTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .designationTextStyles,
  .idNumberTextStyles,
  .inputTextViewStyle,
  .privacyPolicyTextStyles,
  .privacyPolicyTextStyles span,
  .inputTextAreaViewStyle {
    font-size: 18px;
    line-height: 26px;
  }
  .phoneNumberTextStyles,
  .mailTextStyles,
  .sendMessageTextStyles {
    font-size: 20px;
    line-height: 28px;
  }
  .profileImageStyles {
    width: 428px;
    height: 428px;
    /* border-radius: 6px;
    overflow: hidden; */
  }
}
@media screen and (max-width: 1366px) {
  .contactContainerStyles {
    padding: 120px 48px 96px 48px;
  }
  .contactHeadingTextStyles {
    font-size: 64px;
    line-height: 72px;
  }
  .inputTextViewStyle,
  .inputTextAreaViewStyle {
    padding: 12px 24px;
  }
  .inputTextAreaViewStyle {
    height: 168px;
  }
}
@media screen and (max-width: 1148px) {
}
@media screen and (max-width: 1079px) {
  .contactContainerStyles {
    padding: 96px 32px;
    gap: 72px;
  }
  .contactHeadingTextStyles {
    font-size: 48px;
    line-height: 56px;
  }
  .profileImageStyles {
    width: 322px;
    height: 322px;
  }
  .profilesRightContainerStyles,
  .contactFormStyles {
    max-width: 668px;
  }
  .profilesContainerStyles,
  .contactFormContainerStyles {
    flex-direction: column;
    gap: 24px;
  }
  .profilesRightContainerStyles,
  .contactFormStyles {
    align-self: flex-end;
  }
  .footerMainContainerStyles {
    padding: 32px;
  }
  .popUpContentInnerStyles {
    padding: 8px 32px;
  }
  .popUpContentTextStyles,
  .closeTextStyles {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (max-width: 895px) {
  .contactContainerStyles {
    padding: 96px 24px 80px 24px;
    gap: 48px;
  }
  .profilesRightContainerStyles {
    width: fit-content;
  }
  .profileImageStyles {
    width: 298px;
  }
  .footerMainContainerStyles {
    padding: 24px;
  }
  .footerStyles {
    gap: 24px;
  }
  .profilesRightContainerStyles,
  .styles_contactFormStyles,
  .contactFormStyles {
    max-width: 100%;
    width: 100%;
  }
  .profileImageStyles {
    width: 100%;
    height: 298px;
  }
  .contactFormContainerStyles {
    padding-top: 32px;
  }
  .contactFormStyles {
    gap: 40px;
  }
  .popUpContentInnerStyles {
    padding: 8px 24px;
  }
  .inputTextViewStyle,
  .inputTextAreaViewStyle {
    padding: 12px 16px;
  }
  .inputTextAreaViewStyle {
    height: 168px;
  }
}
@media screen and (max-width: 567px) {
  .contactContainerStyles {
    padding: 96px 20px 72px 20px;
    gap: 48px;
  }
  .contactHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .profilesRightContainerStyles {
    flex-direction: column;
    gap: 48px;
  }
  .profileImageStyles,
  .profilesRightContainerStyles,
  .profileCardStyles {
    width: 100%;
  }
  .profileImageStyles {
    height: 374px;
  }
  .profilesContainerStyles {
    gap: 20px;
  }
  .contactFormContainerStyles {
    padding-top: 24px;
  }
  .contactFormStyles {
    gap: 32px;
  }
  .contactFormBottomStyles {
    gap: 20px;
  }
  .footerMainContainerStyles {
    padding: 20px;
  }
  .popUpContentInnerStyles {
    padding: 16px 20px;
    flex-direction: column;
    gap: 16px;
    justify-content: flex-start;
  }
  .closeTextStyles {
    display: flex;
    width: 100%;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 360px) {
  .contactContainerStyles {
    padding: 96px 20px 64px 20px;
  }
  .contactHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .profileImageStyles {
    height: 280px;
  }
  .profileNameTextStyles {
    font-size: 20px;
    line-height: 28px;
  }
  .contactFormContainerStyles {
    padding-top: 16px;
  }
  .privacyPolicyTextStyles {
    flex-wrap: wrap;
  }
  .privacyPolicyTextStyles span {
    width: 100%;
    margin-left: 25px;
  }
  .footerMainContainerStyles {
    padding: 64px 20px 20px 20px;
  }
}
