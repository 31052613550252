.privacyPolicyInnerContainerStyles {
  display: flex;
  max-width: 1728px;
  width: 100%;
  margin: auto;
  padding: 144px 48px 96px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}
.headingTextStyles {
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 128px;
  font-style: normal;
  font-weight: 500;
  line-height: 136px;
}
.headingContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}
.ContentContainerStyles {
  width: 100%;
  max-width: 784px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
}
.contentBlockStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.subHeadingTextStyles {
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.descTextStyles,
.headDescTextStyles,
.descLinkTextStyles {
  color: rgba(5, 5, 5, 0.8);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.headDescTextStyles {
  width: 100%;
  max-width: 384px;
}
.descLinkTextStyles {
  color: var(--blue);
  cursor: pointer;
  text-decoration: none;
}
/* .descLinkTextStyles:hover {
  border-bottom: 2px solid var(--blue);
} */
.footerMainContainerStyles {
  padding: 48px;
  height: fit-content;
}
.footerStyles {
  border-top: none;
  padding-top: 0px;
}

/************ MEDIA QUERIES*************/
@media screen and (min-width: 1729px) {
  .privacyPolicyInnerContainerStyles {
    padding: 144px 64px 96px 64px;
  }
  .subHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .headDescTextStyles {
    max-width: 432px;
  }
  .ContentContainerStyles {
    max-width: 880px;
  }
  .descTextStyles,
  .headDescTextStyles,
  .descLinkTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
  .footerMainContainerStyles {
    padding: 48px 64px;
  }
}
@media screen and (min-width: 1513px) and (max-width: 1728px) {
  .privacyPolicyInnerContainerStyles {
    padding: 144px 64px 96px 64px;
  }
  .subHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .ContentContainerStyles {
    max-width: 880px;
  }
  .headDescTextStyles {
    max-width: 432px;
  }
  .descTextStyles,
  .headDescTextStyles,
  .descLinkTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
  .footerMainContainerStyles {
    padding: 48px 64px;
  }
}
@media screen and (max-width: 1366px) {
  .privacyPolicyInnerContainerStyles {
    padding: 120px 48px 96px 48px;
  }

  .headingTextStyles {
    font-size: 96px;
    line-height: 104px;
  }
  .subHeadingTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
}
@media screen and (max-width: 1148px) {
}
@media screen and (max-width: 1079px) {
  .privacyPolicyInnerContainerStyles {
    padding: 96px 32px;
  }

  .headingTextStyles {
    font-size: 80px;
    line-height: 88px;
  }
}
@media screen and (max-width: 895px) {
  .privacyPolicyInnerContainerStyles {
    padding: 96px 24px 80px 24px;
  }

  .headingTextStyles {
    font-size: 64px;
    line-height: 72px;
  }
  .headDescTextStyles {
    max-width: 100%;
  }
  .ContentContainerStyles {
    max-width: 100%;
  }
  .footerMainContainerStyles {
    padding: 24px;
  }
  .footerStyles {
    gap: 24px;
  }
}
@media screen and (max-width: 567px) {
  .privacyPolicyInnerContainerStyles {
    padding: 96px 20px 72px 20px;
  }

  .headingTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .subHeadingTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .contentBlockStyles {
    gap: 8px;
  }
  .footerMainContainerStyles {
    padding: 72px 20px 20px 20px;
  }
  .footerStyles {
    gap: 32px;
  }
}
@media screen and (max-width: 360px) {
  .privacyPolicyInnerContainerStyles {
    padding: 96px 20px 64px 20px;
    gap: 40px;
  }
  .ContentContainerStyles {
    gap: 40px;
  }
  .headingTextStyles {
    font-size: 48px;
    line-height: 56px;
  }
  .subHeadingTextStyles {
    font-size: 20px;
    line-height: 28px;
  }
  .footerMainContainerStyles {
    padding: 64px 20px 20px 20px;
  }
}
