.imageWidthStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blogImageStyles {
  width: 100%;
  height: 90vh;
}
.blogDetailsContainerStyles {
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 1728px;
  padding: 144px 48px 96px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}
.blogHeadingContainerStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  width: 100%;
  max-width: 784px;
  margin: auto;
}
.blogTitleMainHeadingTextStyles {
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
}
.blogDescListStyles,
.blogDescTextStyles,
.blogDescTextStyles span {
  color: rgba(5, 5, 5, 0.8);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.blogDescTextStyles span {
  color: var(--blue);
  cursor: pointer;
}
.blogDescListStyles {
  display: flex;
  flex-direction: row;
}
.emailTextStyles,
.emailTextStyles span {
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}
.emailTextStyles span {
  /* text-decoration-line: underline; */
  border-bottom: 1px solid var(--dark);
}
.blogDescSectionStyles,
.blogBottomDescContainerStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  width: 100%;
  max-width: 784px;
  margin: auto;
}
.blogQuoteHeadingTextStyles {
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  width: 100%;
  max-width: 784px;
  margin: auto;
}
.blogSubHeadingTextStyles {
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.blogsSubContentContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 48px;
  margin: auto;
}
.DescListContainerStyles .blogDescTextStyles span {
  color: rgba(5, 5, 5, 0.5);
}
/************** RECENT POSTS SECTION  STYLES*****/
.recentPostsMainContainerStyles {
  display: flex;
  padding: 96px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  max-width: 1728px;
  margin: auto;
}
.recentPostHeadingBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.recentPostHeadingTextStyles {
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
}
.recentPostsCardsContainerStyles {
  display: flex;
  gap: 24px;
  flex-direction: row;
}
.recentPostStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
  /* max-width: 432px; */
}
.imageWidthStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.recentPostImgContainerStyles {
  width: 100%;
}
.recentPostDescTextStyles {
  overflow: hidden;
  color: #050505;
  text-overflow: ellipsis;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.listHeadingTextStyles {
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  /* text-decoration: underline; */
  border-bottom: 1px solid var(--dark);
}
.listDescHeadStyles {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
.DescListContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/*********  MEDIA QUERIES ***************/
@media screen and (min-width: 1728px) {
  .blogDetailsContainerStyles {
    padding: 144px 64px 96px 64px;
  }
  .blogDescTextStyles,
  .blogDescTextStyles span {
    font-size: 18px;
    line-height: 26px;
  }
  .blogSubHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
}
@media screen and (min-width: 1513px) and (max-width: 1728px) {
  .blogDetailsContainerStyles {
    padding: 144px 64px 96px 64px;
  }
  .blogDescTextStyles,
  .blogDescTextStyles span {
    font-size: 18px;
    line-height: 26px;
  }
  .blogSubHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
}
@media screen and (max-width: 1366px) {
  .blogDetailsContainerStyles {
    padding: 120px 48px 96px 48px;
  }

  .blogTitleMainHeadingTextStyles {
    font-size: 48px;
    line-height: 56px;
  }
  .blogSubHeadingTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .recentPostHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .recentPostDescTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .recentPostImgContainerStyles {
    width: 100%;
    /* height: 360px; */
    /* max-width: 378px; */
  }
}
@media screen and (max-width: 1148px) {
}
@media screen and (max-width: 1079px) {
  .blogDetailsContainerStyles {
    padding: 96px 32px;
  }
  .blogTitleMainHeadingTextStyles {
    font-size: 48px;
    line-height: 56px;
  }
  .blogImageStyles {
    height: 568px;
  }
  .blogQuoteHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .recentPostsMainContainerStyles {
    padding: 96px 32px;
  }
  .recentPostDescTextStyles {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .blogSubHeadingTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .listHeadingTextStyles {
    font-size: 20px;
    line-height: 28px;
  }
  .listDescHeadStyles {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (max-width: 895px) {
  .blogDetailsContainerStyles {
    padding: 96px 24px 80px 24px;
  }
  .blogImageStyles {
    height: 468px;
  }
  .recentPostsMainContainerStyles {
    padding: 80px 24px;
    gap: 48px;
  }
  .recentPostsCardsContainerStyles {
    flex-wrap: wrap;
  }
  .recentPostStyles {
    width: calc(50% - 12px);
  }
  .recentPostStyles:first-child {
    width: 100%;
    padding-bottom: 24px;
  }
  .blogBottomDescContainerStyles {
    gap: 8px;
  }
  .DescListContainerStyles {
    gap: 4px;
  }
}
@media screen and (max-width: 567px) {
  .blogDetailsContainerStyles {
    padding: 96px 20px 72px 20px;
    gap: 40px;
  }
  .blogsSubContentContainerStyles {
    gap: 40px;
  }
  .blogTitleMainHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .blogImageStyles {
    height: 374px;
  }
  .blogQuoteHeadingTextStyles {
    font-size: 32px;
    line-height: 40px;
  }
  .blogSubHeadingTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .recentPostsMainContainerStyles {
    padding: 64px 20px;
  }
  .recentPostHeadingTextStyles {
    font-size: 32px;
    line-height: 40px;
  }
  .recentPostHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .recentPostDescTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .recentPostsCardsContainerStyles {
    flex-direction: column;
    width: 100%;
  }
  .recentPostStyles {
    width: 100%;
  }
}
@media screen and (max-width: 360px) {
  .blogDetailsContainerStyles {
    padding: 96px 20px 64px 20px;
    gap: 32px;
  }
  .blogsSubContentContainerStyles {
    gap: 32px;
  }
  .blogTitleMainHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .emailTextStyles,
  .emailTextStyles span {
    font-size: 16px;
    line-height: 24px;
  }
  .blogImageStyles {
    height: 280px;
  }
}
