/* * {
  transition: all 0.6s;
} */

.notFoundMainContainerStyles {
  display: table;
  width: 100%;
  height: 100vh;
  text-align: center;
}

.errorMessageContainerStyles {
  display: table-cell;
  vertical-align: middle;
}

.errorMessageTextStyles {
  font-size: 50px;
  display: inline-block;
  padding-right: 12px;
  animation: type 0.5s alternate infinite;
}
.homeTextStyles {
  text-decoration: underline;
  cursor: pointer;
  color: rgb(53, 17, 255);
}

@keyframes type {
  from {
    box-shadow: inset -3px 0px 0px rgb(53, 17, 255);
  }
  to {
    box-shadow: inset -3px 0px 0px transparent;
  }
}
