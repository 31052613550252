.blogsMainContainerStyles {
  background: var(--white);
}
.imageWidthStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blogsContainerStyles {
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 1728px;
  padding: 144px 48px 96px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 96px;
}
.blogsTopContainerStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
}
.blogsPageHeadingTextStyles {
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
  width: 100%;
  max-width: 784px;
  /* margin-right: auto; */
  margin-left: 0;
}
.tabNamesContainerStyles {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.activeTabNameTextStyles,
.tabNameTextStyles {
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  /* padding-bottom: 8px; */
  border-bottom: 2px solid var(--dark);
  cursor: pointer;
}
.tabNameTextStyles {
  color: rgba(5, 5, 5, 0.5);
  border-bottom: none;
}
.singleBlogCardsContainerStyles {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
/* .blogsCardsContainerStyles {
  display: flex;
  flex-direction: row;
  gap: 24px;
  width: 100%;
} */
.blogsCardsContainerStyles {
  display: grid;
  /* grid-template-columns: auto auto auto; */
  grid-template-columns: repeat(3, calc((100% - 48px) / 3));
  column-gap: 24px;
  row-gap: 96px;
  width: 100%;
}
.largeBlogCardStyles {
  max-width: 784px;
}
.dataNotFoundTextStyles {
  color: var(--dark);
  text-align: center;
  width: 100%;
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 48px;
}
/*********  MEDIA QUERIES  **********/
@media screen and (min-width: 1728px) {
  .blogsContainerStyles {
    padding: 144px 64px 96px 64px;
    gap: 96px;
  }
}
@media screen and (min-width: 1513px) and (max-width: 1728px) {
  .blogsContainerStyles {
    padding: 144px 64px 96px 64px;
    gap: 96px;
  }
}
@media screen and (max-width: 1366px) {
  .blogsContainerStyles {
    padding: 120px 48px 96px 48px;
    gap: 72px;
  }
  .blogsCardsContainerStyles {
    row-gap: 72px;
  }
  .blogsPageHeadingTextStyles {
    font-size: 48px;
    line-height: 56px;
    padding-right: 164px;
  }
}
@media screen and (max-width: 1148px) {
}
@media screen and (max-width: 1079px) {
  .blogsContainerStyles {
    padding: 96px 32px;
  }
  .blogsPageHeadingTextStyles {
    font-size: 48px;
    line-height: 56px;
  }
  .blogsCardsContainerStyles {
    grid-template-columns: auto auto;
  }
}
@media screen and (max-width: 895px) {
  .blogsPageHeadingTextStyles {
    padding: 0px;
  }
  .blogsContainerStyles {
    padding: 96px 24px 80px 24px;
    gap: 48px;
  }
  .blogsCardsContainerStyles {
    row-gap: 48px;
  }
  .blogsTopContainerStyles {
    gap: 40px;
  }

  .blogsPageHeadingTextStyles {
    max-width: 100%;
  }
}
@media screen and (max-width: 567px) {
  .blogsContainerStyles {
    padding: 96px 20px 72px 20px;
    gap: 40px;
  }
  .blogsPageHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .blogsTopContainerStyles {
    gap: 32px;
  }
  .blogsCardsContainerStyles {
    grid-template-columns: auto;
    row-gap: 16px;
  }
  .blogCardDividerStyles {
    display: block;
  }
}
@media screen and (max-width: 360px) {
  .blogsContainerStyles {
    padding: 96px 20px 64px 20px;
    gap: 32px;
  }
  .blogsPageHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .blogsTopContainerStyles {
    gap: 24px;
  }
}

@media (hover: none) {
}
