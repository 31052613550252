.imageWidthStyles,
.hoveredImageStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hoveredImageStyles {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
}
.blogCardStyles,
.largeBlogCardStyles {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0 0;
  width: 100%;
  height: fit-content;
  /* max-width: calc((100% - 48px) / 3); */
}
.blogImageStyles,
.largeBlogCardImageStyles {
  position: relative;
  width: 100%;
  height: 432px;
  /* border-radius: 12px; */
  /* border: 1px solid rgba(5, 5, 5, 0.5); */
  /* overflow: hidden;   */
}
.blogCardStyles:hover .hoveredImageStyles {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.largeBlogCardStyles:hover .hoveredImageStyles {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.blogDescContainerStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.blogDescTopStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
}
.blogTitleTextStyles {
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.blogDescTextStyles {
  color: rgba(5, 5, 5, 0.8);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.authorNameTextStyles,
.authorNameTextStyles span {
  color: rgba(5, 5, 5, 0.5);
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
/***************/
.largeBlogCardTitleTextStyles {
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.largeBlogCardStyles {
  max-width: 784px;
}
.largeBlogCardImageStyles {
  height: 468px;
}
/************ MEDIA QUERIES *******/
@media screen and (min-width: 1728px) {
  .largeBlogCardImageStyles {
    height: 520px;
  }
  .largeBlogCardStyles {
    max-width: 880px;
  }
  .blogDescTextStyles,
  .authorNameTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
  .blogImageStyles {
    height: 517px;
  }
}

@media screen and (min-width: 1513px) and (max-width: 1728px) {
  .largeBlogCardImageStyles {
    height: 520px;
  }
  .largeBlogCardStyles {
    max-width: 880px;
  }
  .blogDescTextStyles,
  .authorNameTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
  .blogImageStyles {
    height: 517px;
  }
}
@media screen and (max-width: 1366px) {
  .largeBlogCardImageStyles {
    height: 448px;
  }
  .blogImageStyles {
    height: 378px;
  }
  .largeBlogCardTitleTextStyles,
  .blogTitleTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
}
@media screen and (max-width: 1148px) {
  .blogImageStyles {
    height: 312px;
  }
}
@media screen and (max-width: 1079px) {
  .blogTitleTextStyles,
  .largeBlogCardTitleTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .authorNameTextStyles span {
    display: none;
  }
}
@media screen and (max-width: 895px) {
  .largeBlogCardStyles {
    max-width: 100%;
  }
  .largeBlogCardTitleTextStyles {
    font-size: 32px;
    line-height: 40px;
  }
  .blogImageStyles {
    height: 256px;
  }
  .blogDescTextStyles {
    display: none;
  }
  .blogDescContainerStyles {
    gap: 8px;
  }
  .largeBlogCardStyles .blogDescTextStyles {
    display: -webkit-box;
  }
}
@media screen and (max-width: 567px) {
  .largeBlogCardImageStyles {
    height: 374px;
  }
  .largeBlogCardTitleTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .blogCardStyles {
    flex-direction: row;
    width: 100%;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(5, 5, 5, 0.2);
  }
  .largeBlogCardStyles .blogDescContainerStyles {
    width: 100%;
    gap: 8px;
  }
  .blogCardStyles:last-child {
    border-bottom: none;
  }
  .blogDescTextStyles {
    display: none;
  }
  .blogTitleTextStyles {
    font-size: 20px;
    line-height: 28px;
  }
  .blogImageStyles {
    height: 80px;
    width: 80px;
  }
  .blogDescContainerStyles {
    gap: 4px;
    width: calc(100% - 96px);
  }
}
@media screen and (max-width: 360px) {
  .largeBlogCardImageStyles {
    height: 280px;
  }
  .largeBlogCardStyles .blogDescTextStyles {
    display: none;
  }
  .largeBlogCardTitleTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .largeBlogCardStyles .blogDescContainerStyles {
    gap: 4px;
  }
  .blogTitleTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (hover: none) {
  .blogCardStyles:hover .hoveredImageStyles {
    opacity: 0;
  }
  .largeBlogCardStyles:hover .hoveredImageStyles {
    opacity: 0;
  }

  /* .blogCardStyles:active .hoveredImageStyles {
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }
  .largeBlogCardStyles:active .hoveredImageStyles {
    opacity: 1;
    transition: all 0.2s ease-in-out;
  } */
}
