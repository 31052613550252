.aboutBannerOuterContainerStyles,
.aboutMainContainerStyles {
  background: #191a19;
}
.dividerStyles {
  background-color: rgba(255, 255, 255, 0.2);
}
.aboutBannerInnerContainerStyles {
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 1728px;
  /* height: 592px; */
  gap: 96px;
  padding: 144px 48px 96px 48px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
}
.aboutBannerHeadingStyles {
  color: var(--white);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 128px;
  font-style: normal;
  font-weight: 500;
  line-height: 136px;
}
.aboutBannerDiscTextStyles {
  color: var(--white);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  width: 100%;
  max-width: 784px;
  margin-left: auto;
}
.imageWidthStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.rightDownArrowImageStyles {
  cursor: pointer;
  width: 96px;
  height: 96px;
}
.whatWeDoMainContainerStyles {
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 1728px;
  padding: 96px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
}
.whatWeDoHeadingTextStyles {
  color: var(--white);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
}
.whatWeDoRightContainerStyles {
  width: 100%;
  max-width: 784px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.customDividerStyles {
  background-color: var(--white);
}
.whatWeDoContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.whatWeDoNameTextStyles {
  font-size: 32px;
  line-height: 40px;
  height: fit-content;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  width: 100%;
}
.whatWeDoDescTextStyles {
  color: var(--white);
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  width: 100%;
}
.whatWeDoContentStyles {
  display: flex;
  flex-direction: row;
  gap: 48px;
}
/***** approachSteps section styles*************/
.approachMainContainerStyles {
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 1728px;
  padding: 48px;
  flex-direction: column;
  align-items: center;
  gap: 72px;
}
.approachHeadingTextStyles {
  color: var(--white);
  text-align: center;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  width: 100%;
  max-width: 784px;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  /* padding-inline: 146px; */
}
.approachStepsContainerStyles {
  display: flex;
  /* width: 509px;
  height: 476px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
}
.approachStepsBottomContainerStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.approachStepsButtonGroupStyles {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}
.stepCounterTextStyles,
.stepNameTextStyles,
.stepNumberTextStyles {
  color: var(--white);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.stepNameTextStyles {
  background-color: aquamarine;
}
.stepCounterTextStyles {
  color: rgba(255, 255, 255, 0.5);
}
.disabledButtonStyles {
  pointer-events: none;
  background-color: #191a19;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.leftArrowDisablePathStyles,
.rightArrowDisablePathStyles {
  fill: rgba(255, 255, 255, 0.5);
}
.leftArrowBtnStyles,
.rightArrowBtnStyles {
  border: 1px solid var(--white);
  background-color: #191a19;
}
.leftArrowPathStyles,
.rightArrowPathStyles {
  fill: var(--white);
  /* fill-width: 1px; */
}
.leftArrowBtnStyles:hover {
  background-color: var(--springgreen);
  border: 1px solid #191a19;
}
.rightArrowBtnStyles:hover {
  background-color: var(--springgreen);
  border: 1px solid #191a19;
}
.leftArrowBtnStyles:hover .leftArrowPathStyles {
  fill: var(--dark);
}
.rightArrowBtnStyles:hover .rightArrowPathStyles {
  fill: var(--dark);
}
.approachStepCardStyles {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: -16px; */
  position: relative;
}
.stepNumberTextStyles {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: var(--dark);
  left: 132px;
  top: -16px;
  position: absolute;
}
.stepNameTextStyles {
  display: flex;
  width: 320px;
  height: 320px;
  padding: 0px 56px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 90px;
  border: 1px solid var(--white);
  /* background: #fff; */
}
.approachStepsCardsContainerStyles {
  position: relative;
  width: 358px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.approachCardOneStyles,
.approachCardTwoStyles,
.approachCardThreeStyles,
.approachCardFourStyles,
.emptyApproachCardStyles {
  position: absolute;
  left: 0;
  top: 0;
}
.emptyApproachCardStyles {
  width: 320px;
  height: 320px;
  border: 1px solid var(--white);
  border-radius: 48px;
  background-color: var(--dark);
}
.approachCardOneStyles {
  z-index: 5;
  /* transform-origin: 0% 108%; */
  transform: rotate(0deg);
  /* transform: translateX(0%); */
  transition: all 0.3s ease-in-out;
}
.approachCardTwoStyles {
  z-index: 4;
  transform-origin: 0% 108%;
  transform: rotate(6deg);
  /* transform: translateX(10%); */
  transition: all 0.36s ease-in-out;
}
.approachCardThreeStyles {
  z-index: 3;
  transform-origin: 26% 180%;
  transform: rotate(13deg);
  transition: all 0.5s ease-in-out;
}
.approachCardFourStyles {
  z-index: 2;
  transform-origin: 30% 260%;
  transform: rotate(12deg);
  transition: all 0.5s ease-in-out;
}
.approachCardHideStyles {
  /* display: none; */
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  /* z-index: -10; */
  transform-origin: 0% 108%;
  /* transform: rotate(10deg); */
  transform: translateX(150%);
  transition: all 0.3s ease-in-out;
}
.approachCardLeftHideStyles {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 15;
  /* transform-origin: 0% 108%; */
  /* transform: rotate(10deg); */
  transform: translateX(-100%);
  transition: all 0.3s ease-in-out;
}
/*___________________*/
.approachCardStyles {
  display: flex;
  width: 320px;
  height: 320px;
  padding: 24px 24px 16px 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 48px;
}
.stepNameStyles {
  width: 100%;
  padding-top: 8px;
  text-align: center;
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}
/***** values section styles *********/
.teamValuesHeadTextStyles {
  color: var(--white);
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  width: 100%;
  height: fit-content;
  max-width: 384px;
}
/*----------------------*/
.teamValuesMainContainerStyles {
  display: flex;
  width: 100%;
  max-width: 1728px;
  margin: auto;
  padding: 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
}
.valuesCardsMobileViewContainerStyles {
  display: none;
}
.valuesHeadingTextStyles {
  width: 100%;
  max-width: 784px;
  color: var(--white);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
}
.valuesContentContainer {
  display: flex;
  width: 100%;
  max-width: 784px;
  margin-left: auto;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 48px;
}
.valuesBlockStyles {
  width: calc(50% - 24px);
  display: flex;
  padding: 48px 0px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-top: 1px solid var(--white);
}
.valuesBlockHeadingTextStyles {
  color: var(--white);
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.valuesBlockDescTextStyles {
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.teamValuesCardStyles {
  background-color: #28292c;
  color: var(--white);
  border: none;
  padding: 48px 24px;
  max-width: 384px;
}
.teamValuesCardStyles:hover {
  background: #303e37;
  border: none;
}
.valuesCardsContainerStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.ValuesCardsTopContainerStyles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.valuesCardsMiddleContainerStyles {
  width: 100%;
  max-width: 784px;
  margin-left: auto;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 16px;
}
.valuesCardsBottomContainerStyles {
  width: fit-content;
  margin-left: auto;
}
/***********************OUR TEAM STYLES*******************************/
.ourTeamMainContainerStyles {
  display: flex;
  width: 100%;
  max-width: 1728px;
  margin: auto;
  padding: 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 96px;
  color: var(--white);
}
.ourTemHeadTextStyles {
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  width: 100%;
  max-width: 784px;
}
.temCardContainerStyles {
  display: flex;
  padding: 48px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1 0 0;
  width: 384px;
  max-width: 384px;
  border-radius: 12px;
  background: #28292c;
}
.teamTypeTextStyles {
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.teamMembersListContainerStyles {
  width: 100%;
}
.teamMemberNameTextStyles,
.roleTextStyles {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  /* white-space: nowrap; */
  width: 100%;
  /* overflow: hidden; */
}
.roleTextStyles {
  color: rgba(255, 255, 255, 0.5);
}
.dotSymbolStyles {
  border: 1px solid var(--white);
  width: 3px;
  height: 3px;
  background-color: var(--white);
  border-radius: 50%;
  margin-inline: 4px;
}
.teamValuesCardsContainerStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.teamCardsTopContainerStyles {
  display: flex;
  width: 100%;
  max-width: 784px;
  align-items: flex-start;
  margin-left: auto;
  gap: 16px;
}
.footerStyles {
  border-top: 1px solid var(--white);
}
/***********  MEDIA QUERIES **************/
@media screen and (min-height: 595px) {
  .aboutBannerInnerContainerStyles {
    height: 100vh;
  }
}
@media screen and (min-width: 1728px) {
  .aboutBannerInnerContainerStyles {
    padding: 144px 64px 96px 64px;
  }
  .aboutBannerDiscTextStyles,
  .valuesCardsMiddleContainerStyles,
  .approachHeadingTextStyles,
  .ourTemHeadTextStyles,
  .teamCardsTopContainerStyles {
    max-width: 880px;
  }
  .teamValuesMainContainerStyles,
  .approachMainContainerStyles,
  .ourTeamMainContainerStyles {
    padding: 64px;
  }
  .teamValuesCardStyles,
  .teamValuesHeadTextStyles {
    max-width: 432px;
  }
  .temCardContainerStyles {
    max-width: 432px;
    width: 432px;
  }
  .teamValuesHeadTextStyles,
  .approachHeadingTextStyles,
  .ourTemHeadTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .teamMemberNameTextStyles,
  .roleTextStyles,
  .stepCounterTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
  .teamTypeTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .teamValueIconStyles {
    width: 48px;
    height: 48px;
  }
}
@media screen and (min-width: 1513px) and (max-width: 1728px) {
  .aboutBannerInnerContainerStyles {
    padding: 144px 64px 96px 64px;
  }
  .aboutBannerDiscTextStyles,
  .valuesCardsMiddleContainerStyles,
  .approachHeadingTextStyles,
  .ourTemHeadTextStyles,
  .teamCardsTopContainerStyles {
    max-width: 880px;
  }
  .teamValuesMainContainerStyles,
  .approachMainContainerStyles,
  .ourTeamMainContainerStyles {
    padding: 64px;
  }
  .teamValuesCardStyles,
  .teamValuesHeadTextStyles {
    max-width: 432px;
  }
  .temCardContainerStyles {
    max-width: 432px;
    width: 432px;
  }
  .teamValuesHeadTextStyles,
  .approachHeadingTextStyles,
  .ourTemHeadTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .teamMemberNameTextStyles,
  .roleTextStyles,
  .stepCounterTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
  .teamTypeTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .teamValueIconStyles {
    width: 48px;
    height: 48px;
  }
}
@media screen and (max-width: 1366px) {
  .aboutBannerInnerContainerStyles {
    padding: 120px 48px 96px 48px;
  }
  .aboutBannerHeadingStyles {
    font-size: 96px;
    line-height: 104px;
  }
  .aboutBannerDiscTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .whatWeDoHeadingTextStyles,
  .teamValuesHeadTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .approachHeadingTextStyles {
    padding-inline: 0px;
    font-size: 40px;
    line-height: 48px;
  }
  .valuesHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .valuesBlockStyles {
    padding: 48px 0px 24px 0px;
  }
  .ourTemHeadTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .teamTypeTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
}
@media screen and (max-width: 1148px) {
}
@media screen and (max-width: 1079px) {
  .aboutBannerInnerContainerStyles {
    padding: 96px 32px 48px 32px;
  }
  .aboutBannerHeadingStyles {
    font-size: 80px;
    line-height: 88px;
  }
  .teamValuesMainContainerStyles,
  .ourTeamMainContainerStyles {
    padding: 48px 32px;
  }
  .teamValuesHeadTextStyles,
  .ourTemHeadTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .ourTeamMainContainerStyles {
    gap: 72px;
  }
  .teamTypeTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .rightDownArrowImageStyles {
    height: 56px;
    width: 56px;
  }
  .whatWeDoMainContainerStyles {
    padding: 48px 32px;
    gap: 48px;
  }
  .whatWeDoNameTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .approachMainContainerStyles,
  .valuesMainContainerStyles {
    padding: 48px 32px;
    gap: 48px;
  }
}
@media screen and (max-width: 895px) {
  .aboutBannerInnerContainerStyles {
    padding: 96px 24px 80px 24px;
    gap: 80px;
  }
  .aboutBannerHeadingStyles {
    font-size: 64px;
    line-height: 72px;
  }
  .teamValuesMainContainerStyles,
  .ourTeamMainContainerStyles {
    padding: 40px 24px;
  }

  .ValuesCardsTopContainerStyles {
    flex-direction: column;
    gap: 48px;
  }
  .ValuesCardsTopContainerStyles .teamValuesCardStyles {
    margin-left: auto;
  }
  .valuesCardsMiddleContainerStyles {
    flex-direction: column;
  }
  .valuesCardsMiddleContainerStyles .teamValuesCardStyles:nth-child(even) {
    margin-left: auto;
  }
  .valuesCardsBottomContainerStyles {
    margin-left: 0px;
  }
  .teamValuesCardStyles {
    max-width: 360px;
    padding: 24px;
  }
  .ourTeamMainContainerStyles {
    gap: 48px;
  }
  .teamCardsTopContainerStyles {
    flex-direction: column;
  }
  .teamCardsBottomContainerStyles {
    width: 100%;
  }
  .temCardContainerStyles {
    margin-left: auto;
  }
  .teamCardsTopContainerStyles {
    flex-direction: column;
  }
  .teamCardsTopContainerStyles .temCardContainerStyles:nth-child(even) {
    margin-left: 0px;
  }
  .whatWeDoMainContainerStyles {
    padding: 40px 24px;
    gap: 40px;
  }
  .whatWeDoRightContainerStyles {
    max-width: 100%;
    gap: 40px;
  }
  .approachMainContainerStyles,
  .valuesMainContainerStyles {
    padding: 40px 24px;
    gap: 40px;
  }
  .approachMainContainerStyles {
    gap: 48px;
  }
  .valuesContentContainer {
    row-gap: 40px;
    column-gap: 32px;
  }
  .valuesBlockStyles {
    width: calc(50% - 16px);
    padding: 40px 0px 24px 0px;
  }
}
@media screen and (max-width: 567px) {
  .aboutBannerInnerContainerStyles {
    padding: 96px 20px 72px 20px;
    gap: 72px;
    /* height: 100%; */
  }
  .aboutBannerHeadingStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .rightDownArrowImageStyles {
    width: 48px;
    height: 48px;
  }
  .aboutBannerDiscTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .teamValuesMainContainerStyles {
    padding: 40px 0px;
    width: 100%;
    /* overflow: scroll; */
  }
  .teamValuesHeadTextStyles {
    padding-inline: 20px;
    max-width: 100%;
    font-size: 36px;
    line-height: 44px;
  }
  .valuesCardsContainerStyles {
    display: none;
  }
  .valuesCardsMobileViewContainerStyles {
    display: flex;
    flex-direction: column;
    gap: 40px;
    overflow: scroll;
    width: 100%;
  }
  .teamValuesCardStyles {
    width: 286px;
    min-width: 286px;
  }
  .teamValuesCardStyles:first-child {
    margin-left: 20px;
  }
  .teamValuesCardStyles:last-child {
    margin-right: 20px;
  }
  .teamsValueCardsMobileContainerStyles {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 16px;
    overflow-x: scroll;
  }
  .teamNAmeTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .teamValueIconStyles {
    width: 32px;
    height: 32px;
  }
  .teamValuesCardStyles {
    padding: 24px 16px;
  }
  .whatWeDoMainContainerStyles,
  .valuesMainContainerStyles {
    padding: 40px 20px;
    gap: 40px;
  }
  .whatWeDoHeadingTextStyles,
  .ourTemHeadTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .whatWeDoContainerStyles {
    gap: 16px;
  }
  .whatWeDoContentStyles {
    flex-direction: column;
    gap: 0px;
  }
  .approachMainContainerStyles {
    padding: 40px 20px;
    gap: 40px;
  }
  .approachStepsContainerStyles {
    gap: 32px;
  }
  .approachCardStyles {
    width: 256px;
    height: 256px;
  }
  .approachStepsCardsContainerStyles {
    width: 100%;
    max-width: 277px;
    height: 269px;
  }
  .approachStepsContainerStyles {
    overflow: hidden;
    width: 100%;
  }
  /* .approachStepsCardsContainerStyles {
    width: 100%;
    max-width: 374px;
  } */
  .stepImageStyles {
    width: 80px;
    height: 80px;
  }
  .imageWidthStyles {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .approachHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
    text-align: start;
  }
  .stepNameStyles {
    font-size: 20px;
    line-height: 28px;
  }
  .approachCardTwoStyles {
    transform-origin: 20% 108%;
    transform: rotate(5deg);
  }
  .approachCardThreeStyles {
    transform-origin: 16% 170%;
    transform: rotate(8deg);
  }
  .approachCardFourStyles {
    transform-origin: 40% 210%;
    transform: rotate(10deg);
  }
  .approachCardHideStyles {
    transform-origin: 20% 108%;
    transform: rotate(8deg);
  }
  .stepNameTextStyles {
    display: flex;
    width: 256px;
    height: 256px;
    padding: 0px 56px;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  .stepNumberTextStyles {
    left: 104px;
  }
  .valuesHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .valuesBlockStyles {
    width: 100%;
    padding: 16px 0px 0px;
    gap: 8px;
  }
  .valuesContentContainer {
    gap: 40px;
  }
  .valuesBlockHeadingTextStyles,
  .teamTypeTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .ourTeamMainContainerStyles {
    padding: 40px 20px;
    gap: 40px;
  }
  ._ourTemHeadTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .temCardContainerStyles {
    margin: 0px;
    width: 100%;
    max-width: 100%;
    padding: 24px 16px;
    gap: 16px;
  }
}
@media screen and (max-width: 360px) {
  .aboutBannerInnerContainerStyles {
    padding: 96px 20px 64px 20px;
    gap: 64px;
  }
  .aboutBannerHeadingStyles {
    font-size: 48px;
    line-height: 56px;
  }
  .aboutBannerDiscTextStyles {
    font-size: 20px;
    line-height: 24px;
  }

  .teamValuesHeadTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .teamValuesCardStyles {
    width: 256px;
    min-width: 256px;
  }
  .teamNAmeTextStyles,
  .teamTypeTextStyles {
    font-size: 20px;
    line-height: 28px;
  }

  .approachHeadingTextStyles,
  .ourTemHeadTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
}

@media (hover: none) {
  .teamValuesCardStyles:hover {
    background: #28292c;
    border: none;
  }

  .leftArrowBtnStyles:hover {
    background-color: #191a19;
    border: 1px solid var(--white);
  }
  .rightArrowBtnStyles:hover {
    background-color: #191a19;
    border: 1px solid var(--white);
  }
  .leftArrowBtnStyles:hover .leftArrowPathStyles {
    fill: var(--white);
  }
  .rightArrowBtnStyles:hover .rightArrowPathStyles {
    fill: var(--white);
  }

  .leftArrowBtnStyles:active {
    background-color: var(--springgreen);
    border: 1px solid #191a19;
  }
  .rightArrowBtnStyles:active {
    background-color: var(--springgreen);
    border: 1px solid #191a19;
  }
  .leftArrowBtnStyles:active .leftArrowPathStyles {
    fill: var(--dark);
  }
  .rightArrowBtnStyles:active .rightArrowPathStyles {
    fill: var(--dark);
  }
}
