.cookiePolicyMainContainerStyles,
.cookiePolicyMainDarkContainerStyles {
  background: #dadee3;
  position: fixed;
  top: 0px;
  z-index: 10;
  width: 100%;
  color: var(--dark);
}
.cookiePolicyMainDarkContainerStyles {
  background: #191a19;
  color: var(--white);
}
.cookiePolicyInnerContainerStyles {
  display: flex;
  padding: 8px 48px;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  width: 100%;
  max-width: 1728px;
  margin: auto;
}
.cookiePolicyTextStyles,
.cookiePolicyLinkTextStyles,
.cookiePolicyLinkDarkTextStyles {
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.cookiePolicyLinkTextStyles {
  cursor: pointer;
  color: var(--blue);
}
.cookiePolicyLinkDarkTextStyles {
  cursor: pointer;
  color: var(--springgreen);
}
.cookiePolicyRightContainerStyles {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}
.rightMarkIconStyles,
.crossMarkIconStyles {
  width: 16px;
  height: 16px;
}
.rightMarkIconPathStyles,
.crossMarkIconPathStyles {
  stroke: var(--dark);
}
.rightMarkIconDarkPathStyles,
.crossMarkIconDarkPathStyles {
  stroke: var(--white);
}
.agreeTextStyles,
.declineTextStyles {
  font-family: 'NeueHaasDisplay-Medium';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}

/************ MEDIA QUERIES **********/
@media screen and (min-width: 1728px) {
  .cookiePolicyInnerContainerStyles {
    padding: 8px 64px;
  }
  .cookiePolicyTextStyles,
  .agreeTextStyles,
  .declineTextStyles,
  .cookiePolicyLinkTextStyles,
  .cookiePolicyLinkDarkTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (min-width: 1513px) and (max-width: 1728px) {
  .cookiePolicyInnerContainerStyles {
    padding: 8px 64px;
  }
  .cookiePolicyTextStyles,
  .agreeTextStyles,
  .declineTextStyles,
  .cookiePolicyLinkTextStyles,
  .cookiePolicyLinkDarkTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (max-width: 1366px) {
}
@media screen and (max-width: 1148px) {
}
@media screen and (max-width: 1079px) {
  .cookiePolicyInnerContainerStyles {
    padding: 8px 32px;
  }
}
@media screen and (max-width: 895px) {
  .cookiePolicyInnerContainerStyles {
    padding: 8px 24px;
  }
}
@media screen and (max-width: 567px) {
  .cookiePolicyMainContainerStyles,
  .cookiePolicyMainDarkContainerStyles {
    top: unset;
    bottom: 0px;
  }
  .cookiePolicyInnerContainerStyles {
    padding: 16px 20px;
    flex-direction: column;
    align-items: start;
  }
}
@media screen and (max-width: 360px) {
}
