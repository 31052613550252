.loadingMainContainerStyles {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: #fff;
  overflow: none;
}
.loadingContainerStyles {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  height: fit-content;
  display: flex;
  justify-content: center;
  vertical-align: center;
}
.imageStyles {
  width: 180px;
  height: 160px;
  z-index: 0;
  background: none;
  mix-blend-mode: darken;
}
.loadingContainerStyles::before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  inset: 0 0 0 0;
  background: #fff;
  z-index: 1;
  opacity: 0.9;
  transition: transform 3s ease;
  transform: scaleX(0);
  transform-origin: bottom right;
  animation: animate 2s infinite;
}
@keyframes animate {
  0% {
    transform: scaleX(1);
    left: 0;
  }
  100% {
    transform: scaleX(1);
    left: 100%;
  }
}
/**********    MEDIA QUERIES   ********/
@media screen and (max-width: 1079px) {
  .imageStyles {
    width: 144px;
    height: 128px;
  }
}
@media screen and (max-width: 667px) {
  .imageStyles {
    width: 90px;
    height: 80px;
  }
}
