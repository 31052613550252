.homeMainContainerStyles {
  background-color: var(--white);
}
.customCursorStyles {
  color: var(--blue) !important;
  font-weight: 100 !important;
  font-size: 56px !important;
  font-stretch: narrower;
}
/*******BANNER SECTION STYLES ******/
.bannerMainContainerStyles {
  padding: 144px 48px 0px 48px;
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 1728px;
  margin: auto;
}
.bannerTopContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 96px;
}
.bannerHeadingStyles {
  color: var(--dark);
  text-align: center;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 88px;
  width: 100%;
  max-width: 784px;
  padding-inline: 24px;
  margin: auto;
}
.bannerImageStyle {
  width: 100%;
  height: 90vh;
}
.bannerDescStyles {
  width: 100%;
  max-width: 784px;
  display: flex;
  flex-direction: column;
  gap: 48px;
}

/*******  BANNER INFO SECTION STYLES    *******/
.bannerInfoMainContainerStyles {
  padding: 48px 48px 288px 48px;
  width: 100%;
  max-width: 1728px;
  margin: auto;
}
.bannerBottomMobileTextStyles {
  display: none;
}
.bannerBottomAnimContainerStyles,
.bannerBottomAnimTextStyles,
.customCursorStyles,
.bannerBottomTextStyles,
.bannerBottomMobileTextStyles {
  color: var(--dark);
  font-size: 48px;
  font-style: normal;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-weight: 500;
  line-height: 56px;
  align-items: center;
}
.bannerBottomAnimContainerStyles {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: center;
}
.bannerDescTextStyles {
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  max-width: 784px;
}
.bannerBottomContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 48px;
}
/* .bannerBottomTextStyles,
.bannerBottomAnimTextStyles,
.bannerBottomMobileTextStyles {
  text-decoration: underline;
} */
.bannerBottomBtnGroupStyles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
/******** FEATURES SECTION STYLES ***********/
.featuredMainContainerStyles {
  /* max-width: 1728px; */
  padding: 96px 0px;
  position: relative;
  overflow: scroll;
  width: 100%;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.featuresMobileViewContainerStyles,
.featuresButtonGroupStyles {
  display: none;
}
.featuresBottomStyles {
  width: 100%;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.featureHeadingTextStyles {
  /* position: absolute; */
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  margin: 0px;
  left: 48px;
}
.emptyDivStyles {
  width: 576px;
  height: 100px;
  display: none;
}
.emptyDivStyles:first-child {
  width: 576px;
  height: 100px;
  display: flex;
}
.emptyDivStyles::after {
  content: ' ';
  width: 576px;
  height: 100px;
}
.featuresContainerStyles {
  display: flex;
  flex-direction: row;
  gap: 24px;
  /* padding-right: 48px; */
  /* overflow: scroll; */
  overflow-x: auto;
  width: fit-content;
}
.featureContainerStyle {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: fit-content;
}
.featuresContainerStyles div:nth-last-child() {
  padding-right: 48px;
  /* margin-right: 48px; */
  background-color: red;
}

.featureContainerStyle:last-child {
  margin-right: 48px;
}
.bigFeatureCardStyles {
  width: 568px;
  height: 568px;
  position: relative;
}
.hoverImageStyles {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  top: 0;
  left: 0;
  transition: all 0.2s ease-in-out;
}
.featureContainerStyle:hover .hoverImageStyles {
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.smallFeatureCardStyles {
  width: 386px;
  height: 386px;
  position: relative;
}
.projectNameTextStyles,
.projectNameTextStyles span {
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.projectNameTextStyles span {
  color: rgba(5, 5, 5, 0.4);
}
.featureRightUpArrowStyles {
  width: 24px;
  height: 24px;
}
.featureRightArrowPathStyles {
  stroke: var(--dark);
}
.allWorkTextStyles {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
  justify-content: flex-start;
  margin-left: 48px;
  color: var(--dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  transition: all 0.2s ease-in-out;
}
.allWorkTextStyles:hover {
  color: var(--blue);
  transition: all 0.2s ease-in-out;
}
.allWorkTextStyles:hover .featureRightArrowPathStyles {
  stroke: var(--blue);
  transition: all 0.2s ease-in-out;
}
.allWorkMobileTextStyles,
.allWorkMobileTextStyles span {
  display: none;
  color: var(--dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.allWorkMobileTextStyles span {
  display: block;
}
.allWorkMobileTextStyles:active {
  color: var(--blue);
}
.allWorkMobileTextStyles:active .workTextStyles {
  color: var(--blue) !important;
}
.allWorkMobileTextStyles:active .featureRightArrowPathStyles {
  stroke: var(--blue);
}
.featureHeadBlockStyles {
  position: absolute;
  left: 48px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.disabledButtonStyles {
  pointer-events: none;
  border-radius: 16px;
  border: 1px solid rgba(5, 5, 5, 0.5);
}
.rightArrowDisablePathStyles,
.leftArrowDisablePathStyles {
  fill: rgba(5, 5, 5, 0.5);
}
.leftArrowBtnStyles:hover {
  background-color: var(--blue);
  border: 1px solid var(--blue);
  transition: 0.2s all ease-in-out;
}
.rightArrowBtnStyles:hover {
  background-color: var(--blue);
  border: 1px solid var(--blue);
  transition: 0.2s all ease-in-out;
}
.leftArrowBtnStyles:hover .leftArrowPathStyles {
  fill: var(--white);
  transition: 0.2s all ease-in-out;
}
.rightArrowBtnStyles:hover .rightArrowPathStyles {
  fill: var(--white);
  transition: 0.2s all ease-in-out;
}
.leftArrowPathStyles,
.rightArrowPathStyles {
  fill: var(--dark);
  transition: 0.2s all ease-in-out;
}
/****** OUR SERVICES SECTION ********/
.ourServicesMainContainerStyles {
  padding: 96px 48px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 96px;
  max-width: 1728px;
  width: 100%;
}
.ourServicesDescTextStyles {
  width: 100%;
  max-width: 784px;
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
}
.servicesContainerStyles {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 784px;
  margin-left: auto;
  gap: 16px;
}
.servicesContainerStyles2 {
  display: flex;
  flex-direction: row;
  max-width: 784px;
  margin-right: auto;
  gap: 16px;
}
.allServicesContainerStyles {
  width: 100%;
  max-width: 784px;
  margin-left: auto;
  display: flex;
  flex-direction: column;
  gap: 48px;
}
.userServicesBtnStyles {
  width: 100%;
  max-width: 784px;
  margin-left: auto;
}
.serviceIdTextStyles {
  font-size: 16px;
  line-height: 24px;
  height: fit-content;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  transition: 0.4s all ease-in-out;
}
.serviceContainerStyles:hover .serviceIdTextStyles {
  opacity: 0;
  transition: 0.4s all ease-in-out;
}
.rightAngledBlueArrowIconStyles {
  position: absolute;
  width: 40px;
  height: 40px;
  left: 0px;
  opacity: 0;
  transition: 0.2s all ease-in-out;
}
.serviceContainerStyles:hover .rightAngledBlueArrowIconStyles {
  transition: 0.2s all ease-in-out;
  opacity: 1;
}
.serviceHeadingContainerStyles {
  width: 100%;
  display: flex;
  flex-direction: row;
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-style: normal;
  font-weight: 500;
  gap: 8px;
  position: relative;
  transition: 0.2s all ease-in-out;
}
.serviceContainerStyles:hover .serviceHeadingContainerStyles {
  gap: 24px;
  transition: 0.2s all ease-in-out;
}
.serviceContainerStyles {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.serviceContentStyles {
  display: flex;
  flex-direction: row;
  gap: 48px;
}
.serviceNameTextStyles {
  font-size: 32px;
  line-height: 40px;
  height: fit-content;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  width: 100%;
}
.serviceDescTextStyles {
  color: var(--dark);
  font-family: 'Manrope', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
  width: 100%;
}
.allServicesBlockStyles {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
/************** RECENT POSTS SECTION  STYLES*****/
.recentPostsMainContainerStyles {
  display: flex;
  padding: 96px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  margin: auto;
  width: 100%;
  max-width: 1728px;
}
.recentPostHeadingBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.recentPostHeadingTextStyles {
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
}
.recentPostsCardsContainerStyles {
  display: flex;
  gap: 24px;
  flex-direction: row;
}
.recentPostStyles {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: 100%;
}
.imageWidthStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.recentPostImgContainerStyles {
  position: relative;
  width: 100%;
  height: 432px;
}
.recentPostStyles:hover .hoverImageStyles {
  opacity: 1;
  transition: all 0.2s ease-in-out;
}
.recentPostDescTextStyles {
  overflow: hidden;
  color: #050505;
  text-overflow: ellipsis;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.recentPostsCardsMobileViewContainerStyles,
.allPostsMobileViewButtonStyles {
  display: none;
}
.recentPostsCardsMobileViewMiddleContainerStyles {
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow: scroll;
  width: 100%;
}
/************************       MEDIA QUERIES     ***********************/

@media screen and (min-width: 1728px) {
  .featureContainerStyle:last-child {
    margin-right: 72px;
  }
  .bannerMainContainerStyles {
    padding: 144px 64px 0px 64px;
  }
  .bannerHeadingStyles {
    font-size: 96px;
    line-height: 104px;
    max-width: 880px;
  }
  .bannerInfoMainContainerStyles {
    padding: 48px 64px 288px 64px;
  }
  .featureHeadingTextStyles {
    left: 64px;
  }
  .ourServicesMainContainerStyles,
  .testimonialMainContainerStyles,
  .recentPostsMainContainerStyles {
    padding: 96px 64px;
  }
  .bannerBottomTextStyles,
  .bannerBottomAnimTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .emptyDivStyles:first-child {
    width: 860px;
  }
  .emptyDivStyles::after {
    content: ' ';
    width: 860px;
  }
  .featureHeadingTextStyles,
  .ourServicesDescTextStyles,
  .clientNameTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .ourServicesDescTextStyles {
    max-width: 880px;
  }
  .allWorkTextStyles {
    font-size: 18px;
    line-height: 26px;
    margin-left: calc((100vw - 1728px) / 2 + 64px);
  }
  .featuresContainerStyles {
    margin-left: calc((100vw - 1728px) / 2 + 64px);
  }
  .featureRightUpArrowStyles {
    width: 26px;
    height: 26px;
  }
  .featureHeadBlockStyles {
    /* width: 100%; */
    left: 64px;
  }
  .featureHeadingTextStyles {
    margin-left: calc((100vw - 1728px) / 2);
  }
  .projectNameTextStyles,
  .projectNameTextStyles span {
    font-size: 18px;
    line-height: 26px;
  }
  .servicesContainerStyles {
    max-width: 880px;
  }
  .testimonialLightDescTextStyles,
  .testimonialLightDescTextStyles span {
    font-size: 18px;
    line-height: 26px;
  }
  .testimonialContentTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .testimonialClientNameTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
  .recentPostHeadingTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .recentPostImgContainerStyles {
    height: 528px;
  }
  .recentPostDescTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
}
@media screen and (min-width: 1513px) and (max-width: 1728px) {
  .featureContainerStyle:last-child {
    margin-right: 72px;
  }
  .bannerMainContainerStyles {
    padding: 144px 64px 0px 64px;
  }
  .bannerInfoMainContainerStyles {
    padding: 48px 64px 288px 64px;
  }
  .featureHeadBlockStyles {
    left: 64px;
  }
  .allWorkTextStyles {
    margin-left: 64px;
  }
  .ourServicesMainContainerStyles,
  .testimonialMainContainerStyles,
  .recentPostsMainContainerStyles {
    padding: 96px 64px;
  }
  .bannerBottomTextStyles,
  .bannerBottomAnimTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .emptyDivStyles:first-child {
    width: 760px;
  }
  .emptyDivStyles::after {
    content: ' ';
    width: 760px;
  }
  .featureHeadingTextStyles,
  .ourServicesDescTextStyles,
  .clientNameTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .ourServicesDescTextStyles {
    max-width: 880px;
  }
  .allWorkTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
  .featureRightUpArrowStyles {
    width: 26px;
    height: 26px;
  }
  .projectNameTextStyles,
  .projectNameTextStyles span {
    font-size: 18px;
    line-height: 26px;
  }
  .servicesContainerStyles {
    max-width: 880px;
  }
  .testimonialLightDescTextStyles,
  .testimonialLightDescTextStyles span {
    font-size: 18px;
    line-height: 26px;
  }
  .testimonialContentTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .testimonialClientNameTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
  .recentPostHeadingTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .recentPostImgContainerStyles {
    height: 528px;
  }
  .recentPostDescTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
}
@media screen and (max-width: 1366px) {
  .bannerMainContainerStyles {
    padding: 120px 48px 0px 48px;
  }
  .bannerTopContainerStyles,
  .ourServicesMainContainerStyles {
    gap: 72px;
  }
  .bannerHeadingStyles {
    font-size: 64px;
    line-height: 72px;
    padding-inline: 96px;
  }
  .bannerDescTextStyles,
  .serviceNameTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .bannerInfoMainContainerStyles {
    padding: 48px 48px 240px 48px;
  }
  .emptyDivStyles {
    width: 376px;
  }
  .bannerBottomTextStyles,
  .ourServicesDescTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .bannerBottomAnimContainerStyles,
  .bannerBottomAnimTextStyles,
  .customCursorStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .customCursorStyles {
    font-size: 38px !important;
  }
  .featureHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .bigFeatureCardStyles {
    height: 486px;
    width: 486px;
  }
  .smallFeatureCardStyles {
    width: 360px;
    height: 360px;
  }
  .clientNameTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .testimonialContentTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .recentPostHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .recentPostDescTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .recentPostImgContainerStyles {
    width: 100%;
    height: 378px;
  }
}
@media screen and (max-width: 1278px) {
  .emptyDivStyles:after {
    width: 468px;
    height: 100px;
  }
}
@media screen and (max-width: 1148px) {
  .emptyDivStyles:first-child {
    display: none;
  }
  .emptyDivStyles::after {
    display: none;
  }
  .featuredMainContainerStyles {
    gap: 48px;
  }
  .featuresContainerStyles {
    margin-left: 48px;
  }
  .featureHeadingTextStyles {
    position: relative;
    margin-left: 0px;
    left: 0px;
  }
  .allWorkTextStyles {
    display: none;
  }
  .allWorkMobileTextStyles {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .featureHeadBlockStyles {
    padding-inline: 48px;
    position: relative;
    left: 0px;
  }
  .ourServicesDescTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .recentPostImgContainerStyles {
    height: 312px;
  }
}
@media screen and (max-width: 1079px) {
  .bannerMainContainerStyles {
    padding: 96px 32px 0px 32px;
    gap: 32px;
  }
  .bannerImageStyle {
    height: 568px;
  }
  .bannerInfoMainContainerStyles {
    padding: 32px 32px 120px 32px;
  }
  .bannerTopContainerStyles {
    gap: 48px;
  }
  .bannerHeadingStyles {
    font-size: 48px;
    line-height: 56px;
    padding-inline: 156px;
  }
  .bannerBottomBtnGroupStyles {
    flex-direction: column;
    gap: 48px;
  }
  .emptyDivStyles {
    display: none;
  }
  .featuredMainContainerStyles {
    padding: 96px 0px;
  }
  .featureHeadBlockStyles {
    padding-inline: 32px;
  }
  .featuresContainerStyles {
    margin-left: 32px;
  }
  .bigFeatureCardStyles {
    width: 668px;
    height: 486px;
  }
  .ourServicesMainContainerStyles {
    padding: 96px 32px;
  }
  .allServicesContainerStyles {
    gap: 40px;
  }
  .testimonialMainContainerStyles,
  .recentPostsMainContainerStyles {
    padding: 96px 32px;
  }
  .recentPostImgContainerStyles {
    height: 261px;
  }
  .recentPostDescTextStyles {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 24px;
    line-height: 32px;
  }
}
@media screen and (max-width: 895px) {
  .featureContainerStyle:last-child {
    margin-right: 0px;
  }
  .bannerMainContainerStyles {
    padding: 96px 24px 0px 24px;
    gap: 24px;
  }
  .bannerHeadingStyles {
    width: 100%;
    padding-inline: 0px;
    text-align: start;
  }
  .bannerInfoMainContainerStyles {
    padding: 24px 24px 80px 24px;
  }
  .bannerTopContainerStyles {
    gap: 32px;
  }
  .bannerImageStyle {
    height: 486px;
  }
  .bannerBottomContainerStyles,
  .bannerBottomBtnGroupStyles {
    gap: 40px;
  }
  .featuresWrapperStyles {
    display: none;
  }
  .featureHeadBlockStyles {
    padding: 0px;
  }
  .featuredMainContainerStyles {
    padding: 80px 24px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  .featuresContainerStyles {
    display: none;
  }
  .featuresMobileViewContainerStyles {
    display: block;
  }
  .featuresBottomStyles {
    display: flex;
  }
  .bigFeatureCardStyles {
    width: 100%;
    height: 486px;
  }
  .ourServicesMainContainerStyles {
    padding: 80px 24px;
    gap: 48px;
  }
  .ourServicesDescTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .servicesContainerStyles,
  .servicesContainerStyles2 {
    margin: 0px;
  }
  .serviceCardStyles:nth-child(odd) {
    margin-left: auto;
  }
  .serviceContainerStyles {
    gap: 16px;
  }
  .servicesContainerStyles,
  .servicesContainerStyles2 {
    flex-direction: column;
  }
  .rightAngledBlueArrowIconStyles {
    width: 32px;
    height: 32px;
  }
  .testimonialMainContainerStyles,
  .recentPostsMainContainerStyles {
    padding: 80px 24px;
    gap: 40px;
  }
  .testimonialContentTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .testimonialTopLeftContainerStyles,
  .testimonialBottomContainerStyles {
    gap: 16px;
  }
  .recentPostsCardsContainerStyles {
    flex-wrap: wrap;
  }
  .recentPostStyles {
    width: calc((100% - 24px) / 2);
  }
  .recentPostStyles:first-child {
    width: 100%;
    padding-bottom: 16px;
  }
  .recentPostStyles:first-child .recentPostImgContainerStyles {
    height: 448px;
  }
  .featuresButtonGroupStyles {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }
}
@media screen and (max-width: 567px) {
  .bannerHeadingStyles {
    font-size: 40px;
    line-height: 48px;
    padding-inline: 0px;
    text-align: start;
    max-width: 340px;
    margin-left: 0;
  }
  .bannerMainContainerStyles {
    padding: 96px 20px 0px 20px;
    gap: 20px;
  }
  .bannerInfoMainContainerStyles {
    padding: 24px 20px 72px 20px;
  }
  .bannerTopContainerStyles,
  .bannerBottomContainerStyles {
    gap: 32px;
  }
  .bannerBottomContainerStyles {
    height: fit-content;
    /* padding-bottom: 64px; */
  }
  .bannerImageStyle {
    height: 374px;
  }
  .bannerDescTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .bannerBottomBtnGroupStyles {
    flex-direction: column;
    gap: 32px;
  }
  .bannerBottomTextStyles {
    display: none;
  }
  .bannerBottomMobileTextStyles {
    display: block;
  }
  .bannerBottomMobileTextStyles,
  .bannerBottomAnimTextStyles,
  .featureHeadingTextStyles,
  .ourServicesDescTextStyles,
  .clientNameTextStyles,
  .recentPostHeadingTextStyles,
  .bannerBottomAnimContainerStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .customCursorStyles {
    font-size: 36px !important;
  }
  .featuredMainContainerStyles,
  .ourServicesMainContainerStyles,
  .testimonialMainContainerStyles {
    padding: 72px 20px;
  }
  .ourServicesMainContainerStyles {
    gap: 40px;
  }
  .bigFeatureCardStyles,
  .smallFeatureCardStyles {
    height: 448px;
    width: 100%;
  }
  .allWorkMobileTextStyles span {
    display: none;
  }
  .emptyDivStyles {
    display: none;
  }
  .featureContainerStyle {
    width: calc(100vw - 40px);
  }
  .featuresContainerStyles {
    top: 132px;
  }
  .featuredMainContainerStyles {
    gap: 0px;
  }
  .featuresMobileViewContainerStyles {
    padding: 32px 0px 24px 0px;
  }
  .serviceContentStyles {
    flex-direction: column;
    gap: 8px;
  }
  .ourServicesMainContainerStyles,
  .recentPostsMainContainerStyles {
    padding: 72px 0px;
  }
  .ourServicesDescTextStyles,
  .recentPostHeadingBlock,
  .recentPostStyles {
    padding-inline: 20px;
  }
  .servicesContainerStyles .serviceCardStyles:first-child {
    margin-left: 20px;
  }
  .servicesContainerStyles2 .serviceCardStyles:last-child {
    margin-right: 20px;
  }
  .servicesContainerStyles,
  .allServicesBlockStyles {
    flex-direction: row;
  }
  .allServicesBlockStyles {
    overflow: scroll;
  }
  .testimonialMainContainerStyles,
  .recentPostsMainContainerStyles {
    gap: 32px;
  }
  .serviceContainerStyles,
  .testimonialTopLeftContainerStyles {
    gap: 16px;
  }
  .allServicesContainerStyles {
    gap: 40px;
  }
  .serviceNameTextStyles,
  .recentPostDescTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .testimonialCardStyles {
    padding: 16px;
    gap: 16px;
  }
  .testimonialContentTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
  .recentPostsCardsContainerStyles {
    flex-direction: column;
  }
  .recentPostStyles {
    width: 100%;
    padding-bottom: 0px !important;
  }
  .recentPostsCardsMobileViewMiddleContainerStyles .recentPostStyles {
    padding-inline: 0px;
  }
  .recentPostsCardsMobileViewMiddleContainerStyles {
    padding-inline: 20px;
  }
  .allPostsMobileViewButtonStyles {
    margin-inline: 20px;
  }
  .recentPostsCardsContainerStyles {
    display: none;
  }
  .allPostsButtonStyles {
    display: none;
    opacity: 0;
  }
  .recentPostsCardsMobileViewContainerStyles,
  .allPostsMobileViewButtonStyles {
    display: block;
  }
  .recentPostsCardsMobileViewContainerStyles {
    width: 100%;
    overflow: scroll;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
  .customPostImageStyles {
    width: 224px;
  }
  .recentPostStyles:first-child .recentPostImgContainerStyles {
    height: 374px;
  }
  .recentPostsCardsMobileViewMiddleContainerStyles
    .recentPostStyles
    .recentPostImgContainerStyles {
    height: 286px;
    width: 286px;
  }
}
@media screen and (max-width: 360px) {
  .bannerBottomMobileTextStyles,
  .bannerBottomAnimTextStyles,
  .featureHeadingTextStyles,
  .ourServicesDescTextStyles,
  .clientNameTextStyles,
  .recentPostHeadingTextStyles {
    font-size: 32px;
    line-height: 40px;
  }
  .bannerHeadingStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .bannerImageStyle {
    height: 280px;
  }
  .bannerDescTextStyles {
    font-size: 20px;
    line-height: 28px;
  }
  .bannerInfoMainContainerStyles {
    padding: 24px 20px 64px 20px;
  }
  .customCursorStyles {
    font-size: 32px !important;
  }
  .bigFeatureCardStyles {
    height: 360px;
  }
  .bannerBottomAnimContainerStyles,
  .bannerBottomMobileTextStyles,
  .bannerBottomAnimTextStyles,
  .featureHeadingTextStyles,
  .clientNameTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .featuredMainContainerStyles,
  .testimonialMainContainerStyles {
    padding: 64px 20px;
  }
  .recentPostsMainContainerStyles,
  .ourServicesMainContainerStyles {
    padding: 64px 0px;
  }
  .ourServicesDescTextStyles,
  .recentPostHeadingTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .allPostsButtonStyles {
    opacity: 0;
    width: 0;
    overflow: hidden;
    display: none;
  }
  .recentPostStyles:first-child .recentPostImgContainerStyles {
    height: 286px;
  }
  .recentPostsCardsMobileViewMiddleContainerStyles
    .recentPostStyles
    .recentPostImgContainerStyles {
    height: 256px;
    width: 256px;
  }
}

@media (hover: none) {
  .recentPostStyles:hover .hoverImageStyles {
    opacity: inherit;
  }
  .featureContainerStyle:hover .hoverImageStyles {
    opacity: initial;
  }

  .leftArrowBtnStyles:hover {
    background-color: inherit;
    border: 1px solid var(--dark);
  }
  .leftArrowBtnStyles:active {
    background-color: var(--blue);
    border: 1px solid var(--blue);
  }
  .leftArrowBtnStyles:hover .leftArrowPathStyles {
    fill: var(--dark);
  }
  .leftArrowBtnStyles:active .leftArrowPathStyles {
    fill: var(--white);
  }

  .rightArrowBtnStyles:hover {
    background-color: inherit;
    border: 1px solid var(--dark);
  }
  .rightArrowBtnStyles:active {
    background-color: var(--blue);
    border: 1px solid var(--blue);
  }
  .rightArrowBtnStyles:hover .rightArrowPathStyles {
    fill: var(--dark);
  }
  .rightArrowBtnStyles:active .rightArrowPathStyles {
    fill: var(--white);
  }

  .aboutUsBtnStyles:active {
    background-color: var(--dark);
    color: var(--white);
  }

  .featureContainerStyle:hover .hoverImageStyles {
    opacity: 0;
  }
  /* .featureContainerStyle:active .hoverImageStyles {
    opacity: 1;
    transition: all 0.3s ease-in-out;
  } */

  .allWorkTextStyles:hover {
    color: var(--dark);
  }
  .allWorkTextStyles:hover .featureRightArrowPathStyles {
    stroke: var(--dark);
  }

  .allWorkTextStyles:active {
    color: var(--blue);
    transition: all 0.2s ease-in-out;
  }
  .allWorkTextStyles:active .featureRightArrowPathStyles {
    stroke: var(--blue);
    transition: all 0.2s ease-in-out;
  }
}
