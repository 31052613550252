.imageWidthStyles {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/**   banner styles ***/
.bannerMainContainerStyles {
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 1728px;
  padding: 144px 48px 96px 48px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
  gap: 96px;
}
.bannerHeadingTextStyles {
  width: 100%;
  max-width: 784px;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 88px;
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
}
.bannerDescTextStyles {
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  width: 100%;
  max-width: 784px;
  margin-left: auto;
}

/************************************/
.serviceSectionMainContainerStyles {
  display: flex;
  width: 1440px;
  padding: 96px 48px;
  flex-direction: column;
  align-items: center;
  gap: 96px;
}
.serviceSectionTopStyles {
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  justify-content: space-between;
}
.serviceHeadTextStyles {
  color: var(--dark);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
  width: 100%;
  max-width: 384px;
}
.serviceDecTextStyles {
  width: 100%;
  max-width: 384px;
  color: var(--dark);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px; /* 150% */
}
/*----------------------------------------*/

.approachStepsContainerStyles {
  display: flex;
  /* width: 509px;
  height: 476px; */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
}
.approachStepsBottomContainerStyles {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}
.approachStepsButtonGroupStyles {
  display: flex;
  align-items: flex-start;
  gap: 4px;
}
.stepCounterTextStyles,
.stepNameTextStyles,
.stepNumberTextStyles {
  color: var(--white);
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.stepNameTextStyles {
  background-color: aquamarine;
}
.stepCounterTextStyles {
  color: rgba(255, 255, 255, 0.5);
}
.disabledButtonStyles {
  pointer-events: none;
  background-color: #191a19;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.leftArrowDisablePathStyles,
.rightArrowDisablePathStyles {
  stroke: rgba(255, 255, 255, 0.5);
}
.leftArrowBtnStyles,
.rightArrowBtnStyles {
  border: 1px solid var(--white);
  background-color: #191a19;
}
.leftArrowPathStyles,
.rightArrowPathStyles {
  stroke: var(--white);
}
.leftArrowBtnStyles:hover {
  background-color: var(--springgreen);
  border: 1px solid #191a19;
}
.rightArrowBtnStyles:hover {
  background-color: var(--springgreen);
  border: 1px solid #191a19;
}
.leftArrowBtnStyles:hover .leftArrowPathStyles {
  stroke: var(--dark);
}
.rightArrowBtnStyles:hover .rightArrowPathStyles {
  stroke: var(--dark);
}
.approachStepCardStyles {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* gap: -16px; */
  position: relative;
}
.stepNumberTextStyles {
  display: flex;
  padding: 4px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: var(--dark);
  left: 132px;
  top: -16px;
  position: absolute;
}
.stepNameTextStyles {
  display: flex;
  width: 320px;
  height: 320px;
  padding: 0px 56px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 90px;
  border: 1px solid var(--white);
  /* background: #fff; */
}
.approachStepsCardsContainerStyles {
  position: relative;
  width: 358px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.approachCardOneStyles,
.approachCardTwoStyles,
.approachCardThreeStyles,
.approachCardFourStyles,
.emptyApproachCardStyles {
  position: absolute;
  left: 0;
  top: 0;
}
.emptyApproachCardStyles {
  width: 320px;
  height: 320px;
  border: 1px solid var(--white);
  border-radius: 48px;
  background-color: var(--dark);
}
.approachCardOneStyles {
  z-index: 5;
  /* transform-origin: 0% 108%; */
  transform: rotate(0deg);
  /* transform: translateX(0%); */
  transition: all 0.3s ease-in-out;
}
.approachCardTwoStyles {
  z-index: 4;
  transform-origin: 0% 108%;
  transform: rotate(6deg);
  /* transform: translateX(10%); */
  transition: all 0.3s ease-in-out;
}
.approachCardThreeStyles {
  z-index: 3;
  transform-origin: 26% 180%;
  transform: rotate(13deg);
  transition: all 0.5s ease-in-out;
}
.approachCardFourStyles {
  z-index: 2;
  transform-origin: 30% 260%;
  transform: rotate(12deg);
  transition: all 0.5s ease-in-out;
}
.approachCardHideStyles {
  /* display: none; */
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  /* z-index: -10; */
  transform-origin: 0% 108%;
  /* transform: rotate(10deg); */
  transform: translateX(150%);
  transition: all 0.3s ease-in-out;
}
.approachCardLeftHideStyles {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 15;
  /* transform-origin: 0% 108%; */
  /* transform: rotate(10deg); */
  transform: translateX(-100%);
  transition: all 0.3s ease-in-out;
}
.approachCardStyles {
  display: flex;
  width: 320px;
  height: 320px;
  padding: 24px 24px 16px 24px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  border-radius: 48px;
}
.stepNameStyles {
  width: 100%;
  padding-top: 8px;
  text-align: center;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

/*******  our services section styles   ****/
.ourServicesMainContainerStyes {
  display: flex;
  width: 100%;
  margin: auto;
  max-width: 1728px;
  padding: 96px 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 64px;
}
.ourServicesHeadingTextStyles {
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
}
.servicesContentContainerStyles {
  display: flex;
  width: 100%;
  max-width: 784px;
  margin-left: auto;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
}
.serviceContainerStyles {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 48px;
  align-self: stretch;
  padding-top: 24px;
  border-top: 1px solid #050505;
}
.rightUpArrowImageStyles {
  height: 24px;
  width: 24px;
}
.serviceTypeTextStyles {
  width: calc(100% - 24px);
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.serviceRightContainerStyles {
  width: calc(100% - 24px);
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.serviceDescTextStyles,
.stepsInvolvedTextStyles {
  color: #050505;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}
.stepsInvolvedTextStyles {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

/****** vision section styles *****/
.visionOuterContainStyles {
  width: 100%;

  background: #eff1f4;
}
.visionMainContainStyles {
  display: flex;
  max-width: 1728px;
  margin: auto;
  height: 900px;
  padding: 96px 48px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-shrink: 0;
}
.visionHeadingTextStyles {
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 64px;
  font-style: normal;
  font-weight: 500;
  line-height: 72px;
}
.rightDownArrowImageStyles {
  cursor: pointer;
  width: 96px;
  height: 96px;
}

/***** choose us section styles *********/
.chooseUsMainContainerStyles {
  background-color: #dadee3;
}
.chooseUsContainerStyles {
  display: flex;
  padding: 96px 48px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 1728px;
  margin: auto;
  gap: 96px;
}
.chooseUsHeadingTextStyles {
  width: 100%;
  /* max-width: 784px; */
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 56px;
}
.chooseUsContentContainer {
  display: grid;
  width: 100%;
  max-width: 784px;
  margin-left: auto;
  align-items: flex-start;
  grid-template-columns: auto auto;
  flex-wrap: wrap;
  column-gap: 48px;
  row-gap: 72px;
}
.chooseUsBlockStyles {
  /* width: calc(50% - 24px); */
  display: flex;
  padding-top: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-top: 1px solid #050505;
}
.chooseUsBlockHeadingTextStyles {
  color: #050505;
  font-family: 'NeueHaasDisplay-Medium', sans-serif;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
.chooseUsBlockDescTextStyles {
  color: #050505;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 24px;
}

/********  testimonial section styles  *****/
.testimonialContainerStyles {
  width: 100%;
}

/******* MEDIA QUERIES *****/

@media screen and (min-height: 595px) {
  .bannerMainContainerStyles {
    height: 100vh;
  }
}

@media screen and (min-width: 1728px) {
  .bannerMainContainerStyles {
    padding: 144px 64px 96px 64px;
  }
  .chooseUsContainerStyles {
    padding: 96px 64px;
  }
  .chooseUsHeadingTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .chooseUsBlockHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .chooseUsBlockDescTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (min-width: 1513px) and (max-width: 1728px) {
  .bannerMainContainerStyles {
    padding: 144px 64px 96px 64px;
  }
  .chooseUsContainerStyles {
    padding: 96px 64px;
  }
  .chooseUsHeadingTextStyles {
    font-size: 56px;
    line-height: 64px;
  }
  .chooseUsBlockHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .chooseUsBlockDescTextStyles {
    font-size: 18px;
    line-height: 26px;
  }
}
@media screen and (max-width: 1366px) {
  .bannerMainContainerStyles {
    padding: 120px 48px 96px 48px;
    gap: 96px;
  }
  .bannerHeadingTextStyles {
    font-size: 64px;
    line-height: 72px;
  }
  .bannerDescTextStyles,
  .chooseUsBlockHeadingTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .chooseUsHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .visionMainContainStyles {
    height: 810px;
  }
}
@media screen and (max-width: 1148px) {
}
@media screen and (max-width: 1079px) {
  .bannerMainContainerStyles {
    padding: 96px 32px;
  }
  .bannerHeadingTextStyles,
  .visionHeadingTextStyles {
    font-size: 48px;
    line-height: 56px;
  }
  .rightDownArrowImageStyles {
    width: 56px;
    height: 56px;
  }
  .ourServicesMainContainerStyes,
  .chooseUsContainerStyles {
    padding: 96px 32px;
  }
  .ourServicesHeadingTextStyles,
  .chooseUsHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .visionMainContainStyles {
    height: auto;
    gap: 56px;
    padding: 96px 32px;
  }
}
@media screen and (max-width: 895px) {
  .bannerMainContainerStyles {
    padding: 96px 24px 48px 24px;
    gap: 48px;
  }
  .ourServicesMainContainerStyes {
    padding: 80px 24px;
    gap: 40px;
  }
  .chooseUsContainerStyles {
    padding: 80px 24px;
    gap: 72px;
  }
  .chooseUsHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .chooseUsContentContainer {
    column-gap: 40px;
    row-gap: 64px;
  }
  .servicesContentContainerStyles {
    max-width: 100%;
    gap: 40px;
  }
  .serviceContainerStyles {
    gap: 0;
  }
  .serviceTypeTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .visionMainContainStyles {
    padding: 80px 24px;
    gap: 48px;
  }
  .chooseUsBlockStyles {
    padding-top: 24px;
  }
  .chooseUsBlockHeadingTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
}
@media screen and (max-width: 567px) {
  .bannerMainContainerStyles {
    padding: 96px 20px 72px 20px;
  }
  .bannerDescTextStyles {
    font-size: 24px;
    line-height: 32px;
  }
  .chooseUsHeadingTextStyles {
    font-size: 32px;
    line-height: 40px;
  }
  .bannerHeadingTextStyles,
  .visionHeadingTextStyles {
    font-size: 40px;
    line-height: 48px;
  }
  .ourServicesMainContainerStyes,
  .visionMainContainStyles,
  .chooseUsContainerStyles {
    padding: 72px 20px;
  }
  .chooseUsContainerStyles {
    gap: 40px;
  }
  .chooseUsContentContainer {
    grid-template-columns: auto;
    gap: 32px;
  }
  .ourServicesHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .serviceContainerStyles {
    padding-top: 16px;
    flex-direction: column;
    gap: 8px;
  }
  .serviceTypeTextStyles,
  .chooseUsBlockHeadingTextStyles {
    font-size: 24px;
    line-height: 32px;
    width: 100%;
  }
  .serviceRightContainerStyles {
    gap: 24px;
    width: 100%;
  }

  .chooseUsBlockStyles {
    width: 100%;
    padding: 16px 0px 0px;
    gap: 8px;
  }
}
@media screen and (max-width: 360px) {
  .bannerMainContainerStyles {
    padding: 96px 20px 64px 20px;
    height: 100%;
  }
  .bannerHeadingTextStyles {
    font-size: 36px;
    line-height: 44px;
  }
  .bannerDescTextStyles,
  .chooseUsBlockHeadingTextStyles {
    font-size: 20px;
    line-height: 28px;
  }
  .chooseUsContainerStyles {
    padding: 64px 20px;
  }
  .chooseUsHeadingTextStyles {
    font-size: 28px;
    line-height: 36px;
  }
  .chooseUsBlockStyles {
    gap: 4px;
  }
}
